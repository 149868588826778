import React, { useEffect, useState } from 'react';
import { parseMarkdown } from '../utils/markdownParser';
import { loadMarkdownFiles } from '../utils/fileLoader';
import { blogPosts } from '../components/Blog/blogPosts';
import './Pages.css';

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        console.log('Starting to load blog posts...');
        const posts = await blogPosts;
        console.log('Initial blogPosts:', posts);
        
        const loadedFiles = await loadMarkdownFiles(posts);
        console.log('Loaded files:', loadedFiles);
        
        const parsedPosts = loadedFiles.map(file => {
          console.log('Parsing file:', file);
          return {
            ...parseMarkdown(file.content),
            path: file.path
          };
        });
        
        console.log('Parsed posts:', parsedPosts);
        const uniquePosts = parsedPosts.filter((post, index, self) =>
          index === self.findIndex((p) => p.path === post.path)
        );
        uniquePosts.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(uniquePosts);
      } catch (error) {
        console.error('Error in loadBlogPosts:', error);
      }
    };

    loadBlogPosts();
  }, []);

  return (
    <div className="page-container">
      <section className="blog-section">
        <div className="blog-grid">
          {posts.map((post, index) => (
            <article 
              key={post.path} 
              className="blog-card"
              onClick={() => {
                const slug = post.title.toLowerCase().replace(/\s+/g, '-');
                const colorIndex = (index % 4) + 1;
                window.location.href = `/blog/${slug}?color=${colorIndex}`;
              }}
            >
              <h2 className={`color-${(index % 4) + 1}`}>{post.title}</h2>
              <div className="blog-meta">
                <span className="blog-date">{post.date}</span>
                <span className="blog-author">by {post.author}</span>
              </div>
              <p className="blog-preview">{post.preview}</p>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Blog; 