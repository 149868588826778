import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseMarkdown } from '../../utils/markdownParser';
import { loadMarkdownFiles } from '../../utils/fileLoader';
import { blogPosts } from './blogPosts';
import ReactMarkdown from 'react-markdown';
import BlogSidebar from './BlogSidebar';
import '../../pages/Pages.css';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);
  const { title } = useParams();
  const colorIndex = new URLSearchParams(window.location.search).get('color') || '1';

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const loadedFiles = await loadMarkdownFiles(blogPosts);
        const parsedPosts = loadedFiles.map(file => ({
          ...parseMarkdown(file.content)
        }));
        
        setAllPosts(parsedPosts);
        const selectedPost = parsedPosts.find(p => 
          p.title.toLowerCase().replace(/\s+/g, '-') === title
        );
        setPost(selectedPost);
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };

    loadPosts();
  }, [title]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className="page-container">
      <div className="blog-layout">
        <BlogSidebar posts={allPosts} />
        <article className="blog-post">
          <h1 className={`color-${colorIndex}`}>{post.title}</h1>
          <div className="blog-meta">
            <span className="blog-date">Published: {post.date}</span>
            <span className="blog-author">Author: {post.author}</span>
          </div>
          <div className="blog-content">
            <ReactMarkdown>{post.content}</ReactMarkdown>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPost; 