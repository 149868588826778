// FeatureListItem.jsx
import FeatureDivider from './FeatureDivider';

const FeatureListItem = ({ children, isFirst, isLast }) => {
  return (
    <li style={{ position: 'relative' }}>
      {isFirst && <FeatureDivider position="top" />}
      {!isLast && <FeatureDivider position="bottom" />}
      {children}
    </li>
  );
};

export default FeatureListItem;