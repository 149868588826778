export function importAll(r) {
  if (!r || !r.keys) {
    console.error('require.context not available');
    return [];
  }
  
  try {
    const files = r.keys().map(key => {
      console.log('Processing file:', key);
      const path = r(key);
      console.log('File path:', path);
      return fetch(path)
        .then(response => {
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          return response.text();
        })
        .then(content => {
          console.log('Loaded content for:', key);
          return {
            path: key,
            content: content
          };
        })
        .catch(error => {
          console.error('Error loading file:', error);
          return {
            path: key,
            content: ''
          };
        });
    });
    return Promise.all(files);
  } catch (error) {
    console.error('Error in importAll:', error);
    return [];
  }
} 