import React from 'react';
import './Pages.css';

const Security = () => {
  return (
    <div className="page-container">
      <div className="legal-content">
        <h1>Security</h1>
        <p className="last-updated">Last Updated: March 14, 2024</p>
        
        <section>
          <h2>Data Security</h2>
          <p>VALDYR.io employs multiple layers of security:</p>
          <ul>
            <li>End-to-end encryption for all data</li>
            <li>Regular security audits and penetration testing</li>
            <li>SOC 2 Type II compliant infrastructure</li>
            <li>Private AI processing within your secure environment</li>
          </ul>
        </section>

        <section>
          <h2>Access Controls</h2>
          <p>We implement strict access controls including:</p>
          <ul>
            <li>Multi-factor authentication</li>
            <li>Role-based access control</li>
            <li>Regular access reviews</li>
          </ul>
        </section>

        <section>
          <h2>Reporting Security Issues</h2>
          <p>To report security concerns, please contact: security@valdyr.io</p>
        </section>
      </div>
    </div>
  );
};

export default Security; 