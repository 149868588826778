// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  overflow: hidden;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: var(--background-color);
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--background-color);
  scroll-behavior: smooth;
  overflow-y: auto;
}

/* Scrollbar styling */
.main-content::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.main-content::-webkit-scrollbar-track {
  background-color: var(--background-color);
  border-radius: 5px;
}

.main-content::-webkit-scrollbar-thumb {
  background-color: var(--text-color-dark-alt);
  border-radius: 5px;
  border: 2px solid var(--background-color);
}

.main-content::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light-alt);
}

.page-content {
  flex-grow: 1;
  color: var(--text-color);
  margin-top: 70px;
  background-color: var(--container-background-color);
  z-index: 20000;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,yCAAyC;AAC3C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,yCAAyC;EACzC,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA,sBAAsB;AACtB;EACE,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,4CAA4C;EAC5C,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,YAAY;EACZ,wBAAwB;EACxB,gBAAgB;EAChB,mDAAmD;EACnD,cAAc;AAChB","sourcesContent":["@import 'colors.css';\n\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: Arial, sans-serif;\n  background-color: var(--background-color);\n  overflow: hidden;\n}\n\n.app-container {\n  display: flex;\n  height: 100vh;\n  overflow: hidden;\n  background-color: var(--background-color);\n}\n\n.main-content {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  background-color: var(--background-color);\n  scroll-behavior: smooth;\n  overflow-y: auto;\n}\n\n/* Scrollbar styling */\n.main-content::-webkit-scrollbar {\n  width: 10px;\n  background-color: transparent;\n}\n\n.main-content::-webkit-scrollbar-track {\n  background-color: var(--background-color);\n  border-radius: 5px;\n}\n\n.main-content::-webkit-scrollbar-thumb {\n  background-color: var(--text-color-dark-alt);\n  border-radius: 5px;\n  border: 2px solid var(--background-color);\n}\n\n.main-content::-webkit-scrollbar-thumb:hover {\n  background-color: var(--text-color-light-alt);\n}\n\n.page-content {\n  flex-grow: 1;\n  color: var(--text-color);\n  margin-top: 70px;\n  background-color: var(--container-background-color);\n  z-index: 20000;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
