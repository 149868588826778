// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background: var(--background-color);
  padding: 4rem 2rem 2rem;
  margin-top: 4rem;
  border-top: 1px solid var(--divider-color);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3rem;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.footer-section h3 {
  color: var(--text-color-light);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.footer-section p,
.footer-section a {
  color: var(--text-color-light-alt);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.footer-section a:hover {
  color: var(--highlight-color);
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--divider-color);
  text-align: center;
  color: var(--text-color-light-alt);
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer {
    padding: 2rem 1rem 1rem;
  }
  
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,uBAAuB;EACvB,gBAAgB;EAChB,0CAA0C;AAC5C;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,2DAA2D;EAC3D,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;;EAEE,kCAAkC;EAClC,qBAAqB;EACrB,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,0CAA0C;EAC1C,kBAAkB;EAClB,kCAAkC;EAClC,iBAAiB;AACnB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,qCAAqC;IACrC,SAAS;EACX;AACF;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".footer {\n  background: var(--background-color);\n  padding: 4rem 2rem 2rem;\n  margin-top: 4rem;\n  border-top: 1px solid var(--divider-color);\n}\n\n.footer-content {\n  max-width: 1200px;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n  gap: 3rem;\n}\n\n.footer-section {\n  display: flex;\n  flex-direction: column;\n  gap: 0.75rem;\n}\n\n.footer-section h3 {\n  color: var(--text-color-light);\n  font-size: 1.2rem;\n  margin-bottom: 0.5rem;\n}\n\n.footer-section p,\n.footer-section a {\n  color: var(--text-color-light-alt);\n  text-decoration: none;\n  font-size: 0.9rem;\n  transition: color 0.2s ease;\n}\n\n.footer-section a:hover {\n  color: var(--highlight-color);\n}\n\n.footer-bottom {\n  max-width: 1200px;\n  margin: 0 auto;\n  margin-top: 3rem;\n  padding-top: 2rem;\n  border-top: 1px solid var(--divider-color);\n  text-align: center;\n  color: var(--text-color-light-alt);\n  font-size: 0.9rem;\n}\n\n@media (max-width: 768px) {\n  .footer {\n    padding: 2rem 1rem 1rem;\n  }\n  \n  .footer-content {\n    grid-template-columns: repeat(2, 1fr);\n    gap: 2rem;\n  }\n}\n\n@media (max-width: 480px) {\n  .footer-content {\n    grid-template-columns: 1fr;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
