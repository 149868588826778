import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import demoData from '../../data/tree.json';
import './Tree.css';

function Tree({ selectedFrameworkId }) {
  const [fullGraphData, setFullGraphData] = useState(null);

  // Function to retrieve CSS variables
  const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

  // Define colors from CSS variables
  const bright_green = getCSSVariable('--healthy-color');
  const bright_pink = getCSSVariable('--unhealthy-color');
  const bright_blue = getCSSVariable('--tooltip-background-color');
  const highlight_color = getCSSVariable('--highlight-color');

  useEffect(() => {
    if (selectedFrameworkId) {
      // For demo purposes, use static data instead of fetching
      const graphData = processGraphData(demoData.framework);
      setFullGraphData(graphData);
    }
  }, [selectedFrameworkId]);

  // Process and structure the data for force-directed tree visualization
  const processGraphData = (framework) => {
    const nodes = [];
    const links = [];

    const addNodeAndLinks = (parent, items, type) => {
      items.forEach(item => {
        const node = { 
          id: `${type}-${item.id}`, 
          name: item.name, 
          type, 
          health: item.healthy ? "healthy" : "unhealthy",
          anyUnhealthyDescendant: !item.healthy 
        };
        nodes.push(node);
        links.push({ source: parent.id, target: node.id });
        
        if (type === "Policy") {
          addNodeAndLinks(node, item.controls, "Control");
        } else if (type === "Control") {
          addNodeAndLinks(node, item.evidences, "Evidence");
        }
      });
    };

    const frameworkNode = { 
      id: `Framework-${framework.id.toString()}`,  // Convert framework.id to string
      name: framework.name, 
      type: "Framework", 
      health: framework.healthy ? "healthy" : "unhealthy",
      anyUnhealthyDescendant: !framework.healthy 
    };
    nodes.push(frameworkNode);

    addNodeAndLinks(frameworkNode, framework.policies, "Policy");

    const nodeMap = new Map();
    nodes.forEach(node => nodeMap.set(node.id, node));

    const computeAnyUnhealthy = (node) => {
      if (node.type === 'Evidence') return node.anyUnhealthyDescendant;
      
      const childLinks = links.filter(link => link.source === node.id);
      const children = childLinks.map(link => nodeMap.get(link.target));
      
      node.anyUnhealthyDescendant = node.health === "unhealthy" || children.some(child => computeAnyUnhealthy(child));
      
      return node.anyUnhealthyDescendant;
    };

    computeAnyUnhealthy(frameworkNode);

    return { nodes, links };
  };

  // Render the force-directed tree
  useEffect(() => {
    if (fullGraphData) {
      renderForceDirectedTree(fullGraphData);
    }
  }, [fullGraphData]);

  const renderForceDirectedTree = (data) => {
    const container = document.getElementById('network');
    const width = container.clientWidth;
    const height = container.clientHeight;
  
    d3.select("#network").selectAll("svg").remove();
  
    const svg = d3.select("#network")
      .append("svg")
      .attr("width", width)
      .attr("height", height);
  
    const g = svg.append("g");
  
    const zoom = d3.zoom()
      .scaleExtent([0.3, 0.3])
      .translateExtent([[0, 0], [width, height]])
      .on("zoom", zoomed)
      .filter(event => false);
  
    svg.call(zoom);
  
    const initialZoomScale = 0.3;
    const initialTranslateX = width / 3;
    const initialTranslateY = height / 3;
    svg.call(zoom.transform, d3.zoomIdentity.translate(initialTranslateX, initialTranslateY).scale(initialZoomScale));
  
    function zoomed(event) {
      g.attr("transform", event.transform);
    }
  
    const tooltip = d3.select("#tooltip");

    const getNodeSize = (d) => {
      if (d.type === "Framework") return 60;
      if (d.type === "Policy") return 30;
      if (d.type === "Control") return 15;
      if (d.type === "Evidence") return 7.5;
      return 8;
    };

    const getNodeWeight = (d) => {
      return -getNodeSize(d) * 10;
    };

    const simulation = d3.forceSimulation(data.nodes)
      .force("link", d3.forceLink(data.links).id(d => d.id).distance(200).strength(1))
      .force("charge", d3.forceManyBody().strength(d => getNodeWeight(d)))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .alphaDecay(0.001)
      .alphaMin(0.001);

    const getLinkAttributes = (d) => {
      const targetNode = d.target;
      if (targetNode.anyUnhealthyDescendant) {
        return { color: bright_pink, width: 4 };
      }
      return { color: bright_blue, width: 2 };
    };

    const link = g.append("g")
      .selectAll("line")
      .data(data.links)
      .enter().append("line")
      .attr("stroke", d => getLinkAttributes(d).color)
      .attr("stroke-width", d => getLinkAttributes(d).width);

    const nodeById = new Map(data.nodes.map(node => [node.id, node]));

    const childrenMap = new Map();
    const parentsMap = new Map();

    data.links.forEach(link => {
      if (!childrenMap.has(link.source.id)) {
        childrenMap.set(link.source.id, new Set());
      }
      childrenMap.get(link.source.id).add(link.target.id);

      if (!parentsMap.has(link.target.id)) {
        parentsMap.set(link.target.id, new Set());
      }
      parentsMap.get(link.target.id).add(link.source.id);
    });

    const getAncestors = (nodeId) => {
      const ancestors = new Set();
      const stack = [nodeId];
      while (stack.length > 0) {
        const current = stack.pop();
        const parents = parentsMap.get(current);
        if (parents) {
          parents.forEach(parent => {
            if (!ancestors.has(parent)) {
              ancestors.add(parent);
              stack.push(parent);
            }
          });
        }
      }
      return ancestors;
    };

    const getDescendants = (nodeId) => {
      const descendants = new Set();
      const stack = [nodeId];
      while (stack.length > 0) {
        const current = stack.pop();
        const children = childrenMap.get(current);
        if (children) {
          children.forEach(child => {
            if (!descendants.has(child)) {
              descendants.add(child);
              stack.push(child);
            }
          });
        }
      }
      return descendants;
    };

    const node = g.append("g")
      .selectAll("circle")
      .data(data.nodes)
      .enter().append("circle")
      .attr("r", getNodeSize)
      .attr("fill", d => d.health === "healthy" ? bright_green : bright_pink)
      .on("mouseover", function(event, d) {
        event.stopPropagation();

        d3.select(this).style("cursor", "pointer");

        const [mouseX, mouseY] = d3.pointer(event, container);
        const ancestors = getAncestors(d.id);
        const descendants = getDescendants(d.id);
        const relatedNodeIds = new Set([...ancestors, ...descendants, d.id]);

        node
          .attr("fill", nodeData => relatedNodeIds.has(nodeData.id) ? highlight_color : (nodeData.health === "healthy" ? bright_green : bright_pink));

        link
          .attr("stroke", linkData => 
            relatedNodeIds.has(linkData.source.id) && relatedNodeIds.has(linkData.target.id) ? highlight_color : getLinkAttributes(linkData).color
          )
          .attr("stroke-width", linkData => 
            relatedNodeIds.has(linkData.source.id) && relatedNodeIds.has(linkData.target.id) ? 4 : getLinkAttributes(linkData).width
          );

        tooltip
          .style("left", `${mouseX + 15}px`)
          .style("top", `${mouseY + 15}px`)
          .html(`<strong>${d.name}</strong>`)
          .classed("visible", true);
      })
      .on("mousemove", function(event, d) {
        const [mouseX, mouseY] = d3.pointer(event, container);
        tooltip
          .style("left", `${mouseX + 15}px`)
          .style("top", `${mouseY + 15}px`);
      })
      .on("mouseout", function(event, d) {
        node
          .attr("fill", nodeData => nodeData.health === "healthy" ? bright_green : bright_pink);
  
        link
          .attr("stroke", d => getLinkAttributes(d).color)
          .attr("stroke-width", d => getLinkAttributes(d).width);
  
        tooltip
          .classed("visible", false);
      });

    simulation.on("tick", () => {
      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);
  
      node
        .attr("cx", d => d.x)
        .attr("cy", d => d.y);
    });

    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
  
    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }
  
    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }
  };

  return (
    <div id="network" style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div id="tooltip" className="tooltip"></div>
    </div>
  );
}

export default Tree;
