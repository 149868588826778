const loadMarkdownFile = async (filePath) => {
  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Failed to load markdown file: ${filePath}`);
    }
    return await response.text();
  } catch (error) {
    console.error(`Error loading markdown file: ${filePath}`, error);
    return null;
  }
};

export const loadMarkdownFiles = async (files) => {
  try {
    const loadedFiles = await Promise.all(
      files.map(async (file) => {
        try {
          // If we have direct content, use it
          if (file.content && !file.content.startsWith('/static/media/')) {
            return {
              path: file.filename,
              content: file.content
            };
          }

          // Otherwise, fetch the content
          const response = await fetch(file.content || file.path);
          if (!response.ok) {
            throw new Error(`Failed to load markdown file: ${file.path}`);
          }
          const content = await response.text();
          
          return {
            path: file.filename,
            content: content
          };
        } catch (error) {
          console.error('Error loading file:', file.path, error);
          return null;
        }
      })
    );
    return loadedFiles.filter(file => file !== null);
  } catch (error) {
    console.error('Error in loadMarkdownFiles:', error);
    return [];
  }
}; 