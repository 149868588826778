import React from 'react';
import './Pages.css';

const Privacy = () => {
  return (
    <div className="page-container">
      <div className="legal-content">
        <h1>Privacy Policy</h1>
        <p className="last-updated">Last Updated: March 14, 2024</p>
        
        <section>
          <h2>Data Collection and Use</h2>
          <p>At VALDYR.io, we collect only the information necessary to provide our services. This includes:</p>
          <ul>
            <li>Account information (email, organization name)</li>
            <li>Usage data to improve our services</li>
            <li>Compliance-related data you input into our platform</li>
          </ul>
        </section>

        <section>
          <h2>Data Protection</h2>
          <p>Your compliance data is encrypted both in transit and at rest. We use industry-standard security measures to protect your information.</p>
        </section>

        <section>
          <h2>AI Processing</h2>
          <p>Our AI processing is performed locally within your secure environment. We never store or use your compliance data for training our models.</p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>For privacy-related questions, contact us at: security@valdyr.io</p>
        </section>
      </div>
    </div>
  );
};

export default Privacy; 