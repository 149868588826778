import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Titlebar from './components/Titlebar/Titlebar';
import routesConfig from './routesConfig';
import Footer from './components/Footer/Footer';
import SignUp from './pages/SignUp';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Security from './pages/Security';

function App() {
  return (
    <div className="app-container">
      <div className="main-content">
        <Titlebar />
        <div className="page-content">
          <Routes>
            {routesConfig.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/security" element={<Security />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
