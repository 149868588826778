import React from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';

const WaitList = () => {
  return (
    <div className="page-container">
      <div className="auth-container">
        <h1>Coming Soon!</h1>
        <p className="auth-description">
          Our platform is not yet ready for primetime, but we're working hard to launch soon.
        </p>
        <p className="auth-description">
          Sign up for our newsletter to be the first to know when we launch and get early access to the platform.
        </p>
        
        <Link 
          to="/signup" 
          className="button primary"
        >
          Join Waitlist
        </Link>
      </div>
    </div>
  );
};

export default WaitList; 