import { importAll } from '../../utils/importHelpers';

let blogPostFiles = [];
try {
  console.log('Attempting to load blog posts with require.context...');
  const context = require.context(
    '../../content/blog',
    true,
    /\.md$/
  );
  console.log('Context keys:', context.keys());
  
  const uniquePaths = Array.from(new Set(context.keys()))
    .filter(key => key.startsWith('./'));

  blogPostFiles = uniquePaths.map(key => {
    const module = context(key);
    return {
      path: key,
      filename: key.replace(/^\.\//, ''),
      content: typeof module === 'string' ? module : module.default || module
    };
  });
  
  console.log('Loaded blog files:', blogPostFiles);
} catch (error) {
  console.error('Error in blogPosts.js:', error);
}

export const blogPosts = blogPostFiles; 