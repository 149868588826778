// File: ./app.valdyr.io/frontend/src/pages/Home.js

import React from 'react';
import { Link } from 'react-router-dom';
import Tree from '../components/Graph/Tree';
import './Pages.css';
import FeatureDivider from '../components/Objects/FeatureDivider';
import FeatureListItem from '../components/Objects/FeatureListItem';

const Home = () => {
  return (
    <div className="page-container">
      <section className="hero-section">
        <div className="hero-content">
          <div className="hero-text-content">
            <h1>Compliance Re-Imagined</h1>
            <p className="hero-text">
              All your Organization's standards, policies, and controls in one place. In one hour or less.
            </p>
            <div className="cta-buttons">
              <Link to="/signup" className="button primary">Get Started</Link>
              <Link to="/blog" className="button secondary">Learn More</Link>
            </div>
          </div>
          <div className="hero-graph">
            <Tree selectedFrameworkId="demo" />
          </div>
        </div>
      </section>

      <section className="frameworks-section">
        <h2>We support the frameworks you use:</h2>
        <div className="frameworks-grid">
          <span className="framework-label framework-label-nist">NIST-CSF</span>
          <span className="framework-label framework-label-soc2">SOC2</span>
          <span className="framework-label framework-label-cmmc">CMMC</span>
          <span className="framework-label framework-label-pci">PCI DSS</span>
        </div>
      </section>

      <section className="features-section">
        <div className="features-grid">
          <div className="feature-card">
            <h3>Human Led</h3>
            <p>You are in control of your compliance environment. We just make compliance less painful.</p>
            <ul>
              <FeatureListItem isFirst={true} isLast={false}>
                Your organization, your compliance. Whether you are a small startup or a large enterprise, Valdyr.io is designed to meet your needs. All compliance content can be edited, audited, and exported, no coding experince required.
              </FeatureListItem>
              <FeatureListItem isFirst={false} isLast={true}>
                Compliance should be visual, and Valdyr.io is designed to be a visual tool. We use graphs, charts, and other visualizations to help you understand your compliance posture.
              </FeatureListItem>
            </ul>
          </div>
          <div className="feature-card">
            <h3>AI Integrated</h3>
            <p>Valdyr.io uses AI to help you understand your data, your organization, and your compliance.</p>
            <ul>
              <FeatureListItem isFirst={true} isLast={false}>
                For enterpise organizations, just paste your existing standards and policies into Valdyr.io and watch as we magically populate all of your Controls and Evidence all visually integrated.
              </FeatureListItem>
              <FeatureListItem isFirst={false} isLast={false}>
                For small organizations, describe your organization's requirements and goals to us using normal language, and our proprietary (AND PRIVATE) language model will dynamically populate your entire compliance environment
              </FeatureListItem>
              <FeatureListItem isFirst={false} isLast={true}>
                You own your data, and we can't see it. Our model is private and only used to generate your compliance environment.
              </FeatureListItem>
            </ul>
          </div>
          <div className="feature-card">
            <h3>Data Driven</h3>
            <p>Valdyr.io is built to facilitate organizational excellence, not just checkbox compliance. To do that, you need to be able to track your compliance data in real time and see what evidence informs a control or policy.</p> 
            <ul>
              <FeatureListItem isFirst={true} isLast={false}>
                Built on top of your existing data, Valdyr.io is designed to integrate with your existing systems and processes.
              </FeatureListItem>
              <FeatureListItem isFirst={false} isLast={false}>
                Visualize your compliance data in real time, and dynamically gauge how a change in one business unit might impact your overall compliance posture.
              </FeatureListItem>
              <FeatureListItem isFirst={false} isLast={true}>
                We enable you to perform risk modeling and simulation without needing a degree in statistics.
              </FeatureListItem>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
