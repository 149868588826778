export const parseMarkdown = (content) => {
  try {
    // Check if content exists and is a string
    if (!content || typeof content !== 'string') {
      throw new Error('Invalid content provided');
    }

    // Look for frontmatter between triple dashes
    const matches = content.match(/^---\n([\s\S]*?)\n---\n([\s\S]*)$/);
    
    if (!matches) {
      console.error('Invalid markdown format - no frontmatter found');
      return {
        title: '',
        date: '',
        author: '',
        content: content || '',
        preview: ''
      };
    }

    const [, frontmatter, mainContent] = matches;

    // Parse frontmatter
    const metadata = frontmatter.split('\n').reduce((acc, line) => {
      const [key, ...valueParts] = line.split(':');
      if (key && valueParts.length > 0) {
        const normalizedKey = key.trim().toLowerCase();
        acc[normalizedKey] = valueParts.join(':').trim();
      }
      return acc;
    }, {});

    // Get preview from first paragraph after headers
    const preview = mainContent
      .split('\n')
      .find(line => line.trim() && !line.startsWith('#')) || '';

    console.log('Parsed metadata:', metadata);
    
    return {
      title: metadata.title || '',
      date: metadata.date || '',
      author: metadata.author || '',
      content: mainContent.trim(),
      preview: preview
    };
  } catch (error) {
    console.error('Error parsing markdown:', error);
    return {
      title: '',
      date: '',
      author: '',
      content: content || '',
      preview: ''
    };
  }
}; 