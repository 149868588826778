import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './Graph/Tree.css';

const getCSSVariable = (variable) => getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
const bright_green = getCSSVariable('--healthy-color');
const bright_pink = getCSSVariable('--unhealthy-color');

const FormVisualization = ({ formData }) => {
  const svgRef = useRef();

  useEffect(() => {
    const container = svgRef.current;
    if (!container) return;

    // Count filled fields
    const requiredFields = ['firstName', 'lastName', 'email', 'companyName', 'jobTitle'];
    const optionalFields = ['employeeCount', 'phoneNumber', 'message'];
    const allFields = [...requiredFields, ...optionalFields];
    
    const filledCount = allFields.filter(field => formData[field]?.trim()).length;
    const emptyCount = allFields.length - filledCount;

    const data = [
      { label: 'Filled', value: filledCount },
      { label: 'Empty', value: emptyCount }
    ];

    // Clear previous chart
    d3.select(container).selectAll("*").remove();

    const width = 80;
    const height = 80;
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select(container)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const pie = d3.pie()
      .sort(null)
      .value(d => d.value);

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    const arcs = svg
      .selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => d.data.label === 'Filled' ? bright_green : bright_pink)
      .attr('stroke', 'var(--background-color-alt)')
      .style('stroke-width', '1px');

  }, [formData]);

  return (
    <div className="form-completion-indicator">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default FormVisualization; 