export const EMAIL_CONFIG = {
  SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID || '',
  TEMPLATE_ID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '',
  PUBLIC_KEY: process.env.REACT_APP_EMAILJS_PUBLIC_KEY || ''
};

export const RECAPTCHA_CONFIG = {
  SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''
};

// Add this to help debug
if (!process.env.REACT_APP_EMAILJS_SERVICE_ID) {
  console.warn('REACT_APP_EMAILJS_SERVICE_ID is not set');
}

if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
  console.warn('REACT_APP_RECAPTCHA_SITE_KEY is not set');
} 