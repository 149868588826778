import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Pages.css';
import FormVisualization from '../components/FormVisualization';
import emailjs from '@emailjs/browser';
import { EMAIL_CONFIG, RECAPTCHA_CONFIG } from '../config/env';

const sendEmail = async (formData) => {
  try {
    const templateParams = {
      to_email: 'sales@valdyr.io',
      subject: `Website Form Submission: ${formData.companyName}`,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      company_name: formData.companyName,
      employee_count: formData.employeeCount,
      job_title: formData.jobTitle,
      phone_number: formData.phoneNumber,
      message: formData.message
    };

    await emailjs.send(
      EMAIL_CONFIG.SERVICE_ID,
      EMAIL_CONFIG.TEMPLATE_ID,
      templateParams,
      EMAIL_CONFIG.PUBLIC_KEY
    );

    return true;
  } catch (error) {
    console.error('Email sending failed:', error);
    throw error;
  }
};

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    employeeCount: '',
    jobTitle: '',
    phoneNumber: '',
    message: '',
    newsletter: true
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!captchaValue) {
      setError('Please complete the CAPTCHA verification');
      return;
    }

    try {
      await sendEmail(formData);
      setSubmitted(true);
    } catch (err) {
      setError('Failed to submit form. Please try again later.');
      console.error('Form submission error:', err);
    }
  };

  if (submitted) {
    return (
      <div className="page-container">
        <div className="signup-layout">
          <div className="info-container">
            <h2 className="info-title">Welcome to Valdyr.io</h2>
            <p className="info-description">
              Valdyr.io revolutionizes organizational compliance by combining human expertise with AI-powered insights. Our platform transforms complex compliance frameworks into intuitive, visual representations that help you understand and manage your compliance posture effortlessly.
            </p>
            <p className="info-description">
              Whether you're a startup navigating your first compliance framework or an enterprise managing multiple standards, our platform adapts to your needs. With our AI-powered tools and visual approach, you can transform complex compliance requirements into actionable insights in hours, not months.
            </p>
            
            <div className="info-section">
              <h3>Our Commitment to You</h3>
              <p>
                Your data privacy and security are our top priorities. We never sell your data, and our AI processing is completely private and secure. Your compliance information stays yours - we're just here to help you manage it better.
              </p>
            </div>

            <div className="info-section">
              <h3>What to Expect</h3>
              <p>
                Once you submit your request, our team will carefully review your organization's needs. We're currently in a controlled release phase to ensure every customer gets the attention they deserve. We'll reach out personally to discuss how Valdyr.io can best serve your compliance needs and get you set up for success.
              </p>
            </div>
          </div>

          <div className="auth-container">
            <h1>Thank You!</h1>
            <p className="success-message">
              We've received your information and will be in touch soon.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="signup-layout">

        <div className="auth-container">
          <FormVisualization formData={formData} />
          <h1>Join Waitlist</h1>
          <p className="form-description">
            Tell us about your organization and we'll get in touch to set up your account once we're ready for your organization.
          </p>
          
          {error && <div className="error-message">{error}</div>}
          
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="form-row">
              <div className="form-group half">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group half">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">Work Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="employeeCount">Number of Employees</label>
              <select
                id="employeeCount"
                name="employeeCount"
                value={formData.employeeCount}
                onChange={handleChange}
              >
                <option value="">Select...</option>
                <option value="1-10">1-10</option>
                <option value="11-50">11-50</option>
                <option value="51-200">51-200</option>
                <option value="201-500">201-500</option>
                <option value="501+">501+</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="jobTitle">Job Title</label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <ReCAPTCHA
                sitekey={RECAPTCHA_CONFIG.SITE_KEY}
                onChange={handleCaptchaChange}
                theme="dark"
              />
            </div>

            <button 
              type="submit" 
              className="button primary full-width"
              disabled={!captchaValue}
            >
              Request Access
            </button>
          </form>
        </div>
        <div className="info-container">
          <h2 className="info-title">Welcome to Valdyr.io</h2>
          <p className="info-description">
            Valdyr.io revolutionizes organizational compliance by combining human expertise with AI-powered insights. Our platform transforms complex compliance frameworks into intuitive, visual representations that help you understand and manage your compliance posture effortlessly.
          </p>
          <p className="info-description">
            Whether you're a startup navigating your first compliance framework or an enterprise managing multiple standards, our platform adapts to your needs. With our AI-powered tools and visual approach, you can transform complex compliance requirements into actionable insights in hours, not months.
          </p>
          
          <div className="info-section">
            <h3>Our Commitment to You</h3>
            <p>
              Your data privacy and security are our top priorities. We never sell your data, and our AI processing is completely private and secure. Your compliance information stays yours - we're just here to help you manage it better.
            </p>
          </div>

          <div className="info-section">
            <h3>What to Expect</h3>
            <p>
              Once you submit your request, our team will carefully review your organization's needs. We're currently in a controlled release phase to ensure every customer gets the attention they deserve. We'll reach out personally to discuss how Valdyr.io can best serve your compliance needs and get you set up for success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp; 