import React from 'react';
import { Link } from 'react-router-dom';

const BlogSidebar = ({ posts }) => {
  // Create a map of post titles to their index in the sorted list
  const sortedPosts = [...posts].sort((a, b) => new Date(b.date) - new Date(a.date));
  const postIndices = new Map(sortedPosts.map((post, index) => [post.title, index]));

  // Group posts by month/year
  const groupedPosts = posts.reduce((acc, post) => {
    const date = new Date(post.date);
    const key = `${date.getFullYear()}-${String(date.getMonth()).padStart(2, '0')}`;
    const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    
    if (!acc[key]) {
      acc[key] = {
        monthYear,
        posts: []
      };
    }
    
    acc[key].posts.push(post);
    return acc;
  }, {});

  // Sort posts within each month group
  Object.values(groupedPosts).forEach(group => {
    group.posts.sort((a, b) => new Date(b.date) - new Date(a.date));
  });

  return (
    <aside className="blog-sidebar">
      <h2>All Posts</h2>
      {Object.entries(groupedPosts)
        .sort(([keyA], [keyB]) => keyB.localeCompare(keyA))
        .map(([key, { monthYear, posts }]) => (
          <div key={key} className="blog-sidebar-group">
            <h3>{monthYear}</h3>
            <ul>
              {posts.map(post => {
                const colorIndex = (postIndices.get(post.title) % 4) + 1;
                return (
                  <li key={post.title}>
                    <Link 
                      to={`/blog/${post.title.toLowerCase().replace(/\s+/g, '-')}?color=${colorIndex}`}
                      className={`blog-sidebar-link hover-color-${colorIndex}`}
                    >
                      {post.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
    </aside>
  );
};

export default BlogSidebar; 