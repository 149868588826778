import React from 'react';
import './Pages.css';

const Terms = () => {
  return (
    <div className="page-container">
      <div className="legal-content">
        <h1>Terms of Service</h1>
        <p className="last-updated">Last Updated: March 14, 2024</p>
        
        <section>
          <h2>Service Agreement</h2>
          <p>By using VALDYR.io, you agree to these terms and our Privacy Policy. Our platform is provided "as is" and we make no warranties about its availability or fitness for a particular purpose.</p>
        </section>

        <section>
          <h2>User Responsibilities</h2>
          <p>You are responsible for:</p>
          <ul>
            <li>Maintaining the confidentiality of your account</li>
            <li>Ensuring the accuracy of your compliance data</li>
            <li>Using our platform in compliance with applicable laws</li>
          </ul>
        </section>

        <section>
          <h2>Intellectual Property</h2>
          <p>You retain all rights to your compliance data. VALDYR.io's platform, including our AI models and visualizations, are our intellectual property.</p>
        </section>
      </div>
    </div>
  );
};

export default Terms; 