// routesConfig.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import Blog from './pages/Blog';
import NotFound from './pages/NotFound';
import WaitList from './pages/WaitList';
import BlogPost from './components/Blog/BlogPost';

const LoginRedirect = () => {
  useEffect(() => {
    const checkAppAvailability = async () => {
      try {
        const response = await fetch('https://app.valdyr.io/health');
        if (response.ok) {
          window.location.href = 'https://app.valdyr.io';
        } else {
          window.location.href = '/waitlist';
        }
      } catch (error) {
        window.location.href = '/waitlist';
      }
    };
    
    checkAppAvailability();
  }, []);

  return null;
};

const routesConfig = [
  { path: '/', element: <Home /> },
  { path: '/blog', element: <Blog /> },
  { path: '/blog/:title', element: <BlogPost /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/waitlist', element: <WaitList /> },
  { path: '/login', element: <LoginRedirect /> },
  { path: '*', element: <NotFound /> }
];

export default routesConfig;
