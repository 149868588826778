import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Titlebar.css';

function Titlebar({ breadcrumbs = [] }) {
  const [query, setQuery] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getColorClass = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const colorIndex = searchParams.get('color');
    
    if (location.pathname.startsWith('/blog')) {
      if (colorIndex) {
        return `color-${colorIndex}`;
      }
      return 'blog-color';
    }
    
    switch (location.pathname) {
      case '/signup':
        return 'signup-color';
      case '/login':
        return 'login-color';
      default:
        return '';
    }
  };

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/blog', label: 'Blog' },
    { path: '/signup', label: 'Join Waitlist' },
    { path: '/login', label: 'Login', highlight: true, hideOnMobile: true }
  ].filter(link => !(link.hideOnMobile && windowWidth <= 480));

  return (
    <div className="title-bar">
      <div className="left-side">
        <Link to="/" className={`brand-title ${getColorClass()}`}>
          <span className="brand-highlight">VALDYR</span>.io
        </Link>
      </div>
      <nav className="nav-links">
        {navLinks.map((link) => (
          <Link
            key={link.path}
            to={link.path}
            className={`nav-link ${location.pathname === link.path ? 'active' : ''} ${link.highlight ? 'highlight' : ''} ${location.pathname === link.path ? getColorClass() : ''}`}
          >
            {link.label}
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default Titlebar;
