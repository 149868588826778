import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>VALDYR.io</h3>
          <p>Making compliance accessible since 2024</p>
          <p>Seattle, WA</p>
        </div>
        
        <div className="footer-section">
          <h3>Contact</h3>
          <p>Email: sales@valdyr.io</p>
          <p>Twitter: @valdyrio</p>
        </div>
        
        <div className="footer-section">
          <h3>Legal</h3>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
          <Link to="/security">Security</Link>
        </div>
        
        <div className="footer-section">
          <h3>Resources</h3>
          <Link to="/blog">Blog</Link>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {currentYear} VALDYR.io. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 